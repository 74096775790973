import * as types from '../types'
import { formattedDate } from '../../utils/TimeUtils'

export const daySummaryRequest = ({ realTime, tractor, date }) => {
    let newDate = date ? date : formattedDate(new Date())
    return {
        type: types.DAY_SUMMARY_REQUEST,
        payload: {
            realTime,
            tractor,
            date: newDate,
        },
    }
}

export const daySummarySuccess = ({
    status,
    area,
    totalTime,
    kms,
    fuelLevel,
    lastActive,
    dailyStatus,
    commutes,
    patches,
    replay,
    patchesLatsCentroid,
    patchesLongsCentroid,
    patchesArea,
    patchesStartTime,
    patchesEndTime,
    beacon,
}) => {
    return {
        type: types.DAY_SUMMARY_SUCCESS,
        payload: {
            status,
            area,
            totalTime,
            kms,
            fuelLevel,
            lastActive,
            dailyStatus,
            commutes,
            patches,
            replay,
            patchesLatsCentroid,
            patchesLongsCentroid,
            patchesArea,
            patchesStartTime,
            patchesEndTime,
            beacon,
        },
    }
}

export const daySummaryFailure = (error) => {
    return {
        type: types.DAY_SUMMARY_FAILURE,
        payload: {},
        error: error,
    }
}
