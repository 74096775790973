import * as types from '../types'

const initialState = {
    selectedUnit: null,
    error: null,
}

export default function selectedUnitReducer(state = initialState, action) {
    switch (action.type) {
        case types.SELECTED_UNIT:
            return {
                ...state,
                selectedUnit: action.payload.requestUnit,
            }

        default:
            return state
    }
}
