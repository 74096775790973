const getImplementData = (replayTimeStamp, beaconData) => {
    if (beaconData?.length > 0) {
        const implementType = []
        replayTimeStamp.forEach((timestamp) => {
            let found = false
            for (let item of beaconData) {
                const startTsMs = new Date(item.start_ts).getTime()
                const endTsMs = new Date(item.end_ts).getTime()
                if (timestamp >= startTsMs && timestamp <= endTsMs) {
                    implementType.push(item.beacon_metadata.implement_type)
                    found = true
                    break
                }
            }
            if (!found) {
                implementType.push('No Implement')
            }
        })
        return implementType
    } else {
        return []
    }
}
export default getImplementData
